<template>
  <section class="st-about gray-p--bg">
    <div class="container">
      <LazyAddressCheck
        v-if="!houseUrl"
        :new-window="true"
        :dark="true"
        title="Введите адрес и получите список доступных провайдеров и тарифов"
        btn="Найти провайдеров"
      />

      <h2 class="st-about__title headline-3">
        Что такое скорость интернета
      </h2>
      <p class="st-about__text title-5 gray-b--text">
        Скорость интернета — это максимальное количество данных, которые принимает или передаёт компьютер либо гаджет за единицу времени. Её также называют пропускной способностью канала. Отдельно измеряется входящая и исходящая скорости. Входящая — это когда вы смотрите видео, сёрфите по сети, что-то скачиваете. А исходящая — когда отправляете файлы, ведёте трансляции.
      </p>

      <h3 class="st-about__sectitle title">
        В чём измеряется
      </h3>
      <p class="st-about__text title-5 gray-b--text">
        Чаще всего в мегабитах (Мбит/с), гигабитах (Гбит/с) и килобитах (Кбит/с) в секунду. Один гигабит — это 1000 мегабит. Один мегабит равен 1024 килобитам. В результатах проверки speedtest вы увидите показатели в мегабитах.
      </p>

      <div class="st-about__card white--bg">
        <p class="st-about__card-title title">
          Что влияет на скорость
        </p>
        <div class="st-about__card-wrap d-flex">
          <ul>
            <span class="title-4">Проводной</span>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Технология интернет-соединения;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Качество коммуникаций и оборудования;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Отсутствие дефектов и неисправностей в интернет-кабеле;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Технические характеристики сетевого адаптера (сетевой карты);
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Работающие программы в фоновом режиме, антивирус.
            </li>
          </ul>
          <ul>
            <span class="title-4">Беспроводной (Wi-Fi)</span>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Технические характеристики вашей модели Wi-Fi роутера;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Количество подключенных устройств;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Wi-Fi в соседних квартирах (создают взаимные помехи);
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Удалённость от роутера;
            </li>
            <li>
              <span class="secondary-dark--bg">
                <img
                  loading="lazy"
                  src="@/assets/images/svg/plus_w.svg"
                  class="d-flex"
                  alt="plus"
                >
              </span>Толщина и количество стен между вами и роутером.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'

const cityStore = useCities()
const houseUrl = computed(() => cityStore.getHouseUrl)
</script>

<style scoped lang="scss">
.st-about {
  padding: 48px 0;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0;
  }

  .ac {
    margin-bottom: 64px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 48px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 4px;
  }

  &__sectitle {
    margin-top: 24px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  &__card {
    margin-top: 20px;
    padding: 24px;
    border: 1px solid color(gray-darker);
    border-radius: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 16px;
    }

    &-title {
      font-weight: 700;
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 12px;
      }
    }
    &-wrap {
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
      }
    }
    ul {
      &:first-child {
        margin-right: 32px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      & > span {
        display: block;
        margin-bottom: 12px;
      }
    }
    li {
      padding-left: 32px;
      margin-top: 8px;
      position: relative;
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding-left: 30px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding-left: 28px;
      }
      span {
        position: absolute;
        left: 0;
        border-radius: 50%;
        img {
          width: 24px;
          height: 24px;
          @media (max-width: getBreakpoint(mobile-lg)) {
            width: 22px;
            height: 22px;
          }
          @media (max-width: getBreakpoint(mobile-md)) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
