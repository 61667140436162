
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46url_93vcmbdVBAWv0tVINWOTgWEQcQyzS_pJpk5KZ0w6u0GEwMeta } from "/app/pages/partners/[...city]/[...url].vue?macro=true";
import { default as internet_45mobile4JdIWaKURxuuLpx_QHOYttS2IGWXuboQ3le70NeU5xwMeta } from "/app/pages/partners/[...city]/internet-mobile.vue?macro=true";
import { default as internet_45tv_45mobileHDy6txhOKRX4IlINTDX0_iug5UqXhnZj2udNW7EIuzUMeta } from "/app/pages/partners/[...city]/internet-tv-mobile.vue?macro=true";
import { default as internet_45tv75Rb2F36lqxCQ82ZLQ4OPhpIETgn_hO24x7UXtHttWAMeta } from "/app/pages/partners/[...city]/internet-tv.vue?macro=true";
import { default as internetaAY2EaLxv6FsJuqSDwjLgtrBTQyRRfergaMfuTLgJG0Meta } from "/app/pages/partners/[...city]/internet.vue?macro=true";
import { default as indexhn5eodHwihMeD9pHtv_OmXz4ylijrwjLKCCLyP6DL_45UMeta } from "/app/pages/partners/[...city]/not-available/index.vue?macro=true";
import { default as quiz_45complete3EUWbuCE7yRFrpvW0d_KT9gmhy4EuDYG_YMVouQpEFIMeta } from "/app/pages/partners/[...city]/quiz-complete.vue?macro=true";
import { default as indexXJZHwwRKLxgFuDricoKnbdCeVXV97jVzOY6ecU4DNpYMeta } from "/app/pages/partners/[...city]/quiz/index.vue?macro=true";
import { default as _91id_93xXPO90KsALuhj0_45ryBrJSu70FSEEbtRzJMfPAGtBkHgMeta } from "/app/pages/partners/[...city]/tariffs/[id].vue?macro=true";
import { default as indexVZgHzckFzqJ9HxDK7E75gvbRiPAnTjlgU7AV2uxcwdAMeta } from "/app/pages/partners/checkout/index.vue?macro=true";
import { default as indexSXL5usQm2AzW9kEYtL1_45SBSRR4dv_45G45l0w9fELJ96kMeta } from "/app/pages/partners/complete/index.vue?macro=true";
import { default as indexHvZHaXx_45AgM9De2ADAqp6B9E_45vcYO4j9OvCGp7BWoxoMeta } from "/app/pages/partners/contacts/index.vue?macro=true";
import { default as index5HELOOCg_45d_Dkv6m9fF8HmkFKjsqVqvSaD1dRbUqOOIMeta } from "/app/pages/partners/health/index.vue?macro=true";
import { default as indexo3spBEKVcOAAnRYw14uDziYhGPGPfahlX3bWXhxhhesMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as indexElozduqjxteFuNnYR698_LvD8dl8UrQg3xV43lPhTq4Meta } from "/app/pages/partners/legal/[...query]/index.vue?macro=true";
import { default as indexn7Yz0dmUlOJsTSLR5wGR94brNLAA_45iIRLaoSZFCg7T8Meta } from "/app/pages/partners/legal/index.vue?macro=true";
import { default as index7KGU5Lx7vCzQLMeqOeeq_454Nfz_skVtQSBRTvCPyh1b0Meta } from "/app/pages/partners/locations/index.vue?macro=true";
import { default as thanks_45for_45choiceBqd0B3w4I2l2DoeB7WJsP5zXcIS2I8HOVha5_1VEc8kMeta } from "/app/pages/partners/thanks-for-choice.vue?macro=true";
export default [
  {
    name: "city-url",
    path: "/:city(.*)*/:url(.*)*",
    component: () => import("/app/pages/partners/[...city]/[...url].vue")
  },
  {
    name: "city-internet-mobile",
    path: "/:city(.*)*/internet-mobile",
    component: () => import("/app/pages/partners/[...city]/internet-mobile.vue")
  },
  {
    name: "city-internet-tv-mobile",
    path: "/:city(.*)*/internet-tv-mobile",
    component: () => import("/app/pages/partners/[...city]/internet-tv-mobile.vue")
  },
  {
    name: "city-internet-tv",
    path: "/:city(.*)*/internet-tv",
    component: () => import("/app/pages/partners/[...city]/internet-tv.vue")
  },
  {
    name: "city-internet",
    path: "/:city(.*)*/internet",
    component: () => import("/app/pages/partners/[...city]/internet.vue")
  },
  {
    name: "city-not-available",
    path: "/:city(.*)*/not-available",
    component: () => import("/app/pages/partners/[...city]/not-available/index.vue")
  },
  {
    name: "city-quiz-complete",
    path: "/:city(.*)*/quiz-complete",
    meta: quiz_45complete3EUWbuCE7yRFrpvW0d_KT9gmhy4EuDYG_YMVouQpEFIMeta || {},
    component: () => import("/app/pages/partners/[...city]/quiz-complete.vue")
  },
  {
    name: "city-quiz",
    path: "/:city(.*)*/quiz",
    meta: indexXJZHwwRKLxgFuDricoKnbdCeVXV97jVzOY6ecU4DNpYMeta || {},
    component: () => import("/app/pages/partners/[...city]/quiz/index.vue")
  },
  {
    name: "city-tariffs-id",
    path: "/:city(.*)*/tariffs/:id()",
    component: () => import("/app/pages/partners/[...city]/tariffs/[id].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexVZgHzckFzqJ9HxDK7E75gvbRiPAnTjlgU7AV2uxcwdAMeta || {},
    component: () => import("/app/pages/partners/checkout/index.vue")
  },
  {
    name: "complete",
    path: "/complete",
    meta: indexSXL5usQm2AzW9kEYtL1_45SBSRR4dv_45G45l0w9fELJ96kMeta || {},
    component: () => import("/app/pages/partners/complete/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/partners/contacts/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: index5HELOOCg_45d_Dkv6m9fF8HmkFKjsqVqvSaD1dRbUqOOIMeta || {},
    component: () => import("/app/pages/partners/health/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "legal-query",
    path: "/legal/:query(.*)*",
    component: () => import("/app/pages/partners/legal/[...query]/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/partners/legal/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/partners/locations/index.vue")
  },
  {
    name: "thanks-for-choice",
    path: "/thanks-for-choice",
    meta: thanks_45for_45choiceBqd0B3w4I2l2DoeB7WJsP5zXcIS2I8HOVha5_1VEc8kMeta || {},
    component: () => import("/app/pages/partners/thanks-for-choice.vue")
  }
]