<template>
  <section class="st-evening gray-p--bg">
    <div class="container">
      <h2 class="st-evening__title headline-3">Почему вечером падает скорость интернета</h2>
      <p class="title-5 gray-b--text">Если вы живёте в многоквартирном доме и пользуетесь вай-фай, эта проблема вам знакома. После шести-семи вечера, особенно по пятницам, интернет начинает тормозить. Всё потому что по статистике именно в это время люди активнее всего «сидят в интернете». Приходят с работы, находят на ютубе любимые шоу, смотрят кино в онлайн-кинотеатрах, общаются в соцсетях. При этом частота большинства роутеров 2,4 ГГц. Одновременное использование этой частоты забивает её помехами и снижает скорость. Кстати можете провести эксперимент сами: измерить скорость по speedtest в будний день часов в 10 утра и провести проверку в пятницу вечером. Скорее всего, у вас тоже будет разница в цифрах.</p>
      <p class="st-evening__sectitle title">Как предотвратить падение скорости интернета вечером</p>
      <p class="title-5 gray-b--text">Простого ответа на этот вопрос до сих пор нет. Если вам нужен стабильный сигнал вечером для работы, подключите кабель к ноутбуку напрямую.
А ещё можете сменить роутер на двухдиапазонный 2,4/5 ГГц, который позволяет переключаться на более свободную частоту. Этот способ поможет, если ваши гаджеты поддерживают диапазон 5 ГГц.</p>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped lang="scss">
.st-evening {
  padding: 48px 0;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0;
  }

  &__title {
    margin-bottom: 4px;
  }
  &__sectitle {
    margin-top: 24px;
    margin-bottom: 4px;
    font-weight: 700;
  }
}
</style>