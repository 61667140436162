import ThumborUrlBuilder from 'thumbor-url-builder'

export default defineNuxtPlugin({
  name: 'thumbor',
  parallel: true,
  setup() {
    const thumborKey = import.meta.env.VITE_THUMBOR_SECURITY_KEY || ''
    const thumborUrl = import.meta.env.VITE_ABSOLUTE_THUMBOR_URL || import.meta.env.VITE_THUMBOR_URL || ''

    const thumborURL = new ThumborUrlBuilder(thumborKey, thumborUrl)

    return {
      provide: {
        thumborURL,
      },
    }
  },
})
