<template>
  <div
    v-if="smartFilters"
    id="smartFilter"
    data-test="smart-filters"
    class="relative smart-filters d-flex"
  >
    <a
      v-if="!($route.name === 'personal-address-slug' || $route.name === 'city-address-slug')"
      :href="
        $route.name === 'city-tariffs-smartFilter'
          ? `/${currentCity?.url}/tariffs/${$route.query.houseType ? '?houseType=' + $route.query.houseType : ''}`
          : `${$route.path}${$route.query.houseType ? '?houseType=' + $route.query.houseType : ''}`
      "
      class="subhead-3 smart-filters__item"
      :class="{
        isActive:
          ($route.name === 'city-tariffs'
            || $route.name === 'city-providers'
            || $route.name === 'city-providers-provider'
            || $route.name === 'personal-address-slug')
          && !$route.query.smartFilter
          && !queryFiltersActive,
      }"
      @click.prevent="$emit('selectSmartFilter', btnAllTariffs)"
    >Все</a>
    <template
      v-for="btn in smartFilters"
      :key="btn.slug"
    >
      <a
        v-if="!btn.asQueryParam && $route.name !== 'city-providers'"
        :href="
          $route.path.includes('/providers/')
            ? `/${currentCity?.url}/providers/${params}/${btn.slug}/${$route.query.houseType ? '?houseType=' + $route.query.houseType : ''}`
            : $route.name === 'personal-address-slug'
              ? `${$route.path}?smartFilter=${btn.slug}${$route.query.houseType ? '&houseType=' + $route.query.houseType : ''}`
              : `/${currentCity?.url}/tariffs/${btn.slug}/${$route.query.houseType ? '?houseType=' + $route.query.houseType : ''}`
        "
        class="subhead-3 smart-filters__item"
        :class="{
          isActive: $route.params?.smartFilter
            ? $route.params?.smartFilter === btn.slug
            : $route.params.smartFilter && $route.params.smartFilter
              ? btn.slug === $route.params.smartFilter
              : $route.query.smartFilter === btn.slug || selected === btn.slug,
        }"
        @click.prevent="$emit('selectSmartFilter', btn)"
      >{{ btn.name }}</a>

      <button
        v-else
        class="subhead-3 smart-filters__item"
        :class="{
          isActive: $route.params?.smartFilter
            ? $route.params?.smartFilter === btn.slug
            : $route.params.smartFilter && $route.params.smartFilter
              ? btn.slug === $route.params.smartFilter
              : $route.query.smartFilter === btn.slug || selected === btn.slug,
        }"
        @click="$emit('selectSmartFilter', btn)"
      >
        {{ btn.name }}
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TariffSmartFilterDisplay } from '~/api'
import { useCities } from '~/store/cites/cities.store'

defineProps({
  selected: {
    type: String,
  },
  queryFiltersActive: {
    type: Boolean,
  },
  smartFilters: {
    type: Array as PropType<TariffSmartFilterDisplay[]>,
  },
})

defineEmits(['selectSmartFilter'])

const $route = useRoute()
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
const btnAllTariffs = ref({
  slug: undefined,
})
const params = computed(() => {
  const arr = $route.path.split('/')
  const index = arr.indexOf('providers')
  return index !== -1 ? arr[index + 1] : undefined
})

onMounted(() => {
  const parent = document.getElementById('smartFilter')
  if (parent) {
    const active = parent.getElementsByClassName('isActive')[0]
    if (active) {
      const elem = active.getBoundingClientRect()
      parent.scrollBy({ left: elem.x - window.innerWidth / 2 })
    }
  }
})
</script>

<style scoped lang="scss">
.smart-filters {
  flex-wrap: wrap;
  overflow: auto;
  &__item {
    border: 1px solid color(gray-darker);
    flex-grow: 0;
    width: unset;
    margin-bottom: 4px;
    background-color: color(gray-darker);
    border-radius: 32px;
    line-height: 20px;
    padding: 7px 11px;
    color: color(black);
    &:not(:last-child) {
      margin-right: 4px;
    }

    &.isActive {
      background-color: color(primary);
      color: color(white);
      border: 1px solid color(primary);
    }
    @media (max-width: getBreakpoint(desktop)) {
      flex-shrink: 0;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 5px 11px;
    }
  }
  @media (max-width: getBreakpoint(desktop)) {
    flex-wrap: nowrap;
    margin-right: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}
</style>
