<template>
  <client-only>
    <div class="main-loader">
      <div class="main-loader__progress">
        <div class="main-loader__progress-parent relative">
          <div
            class="main-loader__progress-child"
            :style="`width: ${progressVal > 0 ? 100 : 0}%; transition: width ${
              loaderTime || 10
            }s linear;`"
          />
          <p class="secondary-dark--text relative fwb">
            {{ Math.round(progressVal) }}%
          </p>
        </div>
        <p class="white--text">
          Ищем актуальные тарифы для вашего дома
        </p>
      </div>
      <div
        class="main-loader__text d-flex flex-column"
        :style="`opacity: ${textOpacity};`"
      >
        <p class="main-loader__text-title white--text">
          {{ currentText.title }}
        </p>
        <p class="main-loader__text-description white--text">
          {{ currentText.description }}
        </p>
      </div>
      <img
        loading="lazy"
        alt="cosmonaut"
        src="@/assets/images/useful/cosmonaut.png"
      >
    </div>
  </client-only>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const mainStore = useMainStore()
const text = computed(() => mainStore.getLoaderText)
const loaderTime = computed(() => mainStore.getLoaderTime || 10)
const textCounter = ref(0)
const progressVal = ref(0)
const $route = useRoute()
const currentText = computed(() => text.value[textCounter.value] || { title: '', description: '' })

const closeFn = () => {
  if (['personal-address-slug', 'city-providers-not-found', 'city-address-slug'].includes($route.name)) {
    mainStore.$patch({ showLoader: false })
  }
  else setTimeout(closeFn, 300)
}
const animateProgress = () => {
  const startTime = performance.now()
  const duration = loaderTime.value * 1000

  const updateProgress = (currentTime: number) => {
    const elapsed = currentTime - startTime
    const progress = Math.min((elapsed / duration) * 100, 100)

    progressVal.value = progress

    if (progress < 100) {
      requestAnimationFrame(updateProgress)
    }
    else {
      closeFn()
    }
  }

  requestAnimationFrame(updateProgress)
}

const textOpacity = ref('1')

onBeforeMount(() => {
  animateProgress()
  document.documentElement.classList.add('hidden')
})
onBeforeUnmount(() => {
  document.documentElement.classList.remove('hidden')
})
</script>

<style lang="scss" scoped>
.main-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    115.01deg,
    #2d2bad -0.95%,
    rgba(77, 75, 204, 0.994792) -0.94%,
    #1bb1d0 105.39%
  );
  z-index: 3001;
  padding: 128px 112px;

  &__progress {
    margin-bottom: 80px;
    &-parent {
      height: 24px;
      border-radius: 24px;
      background-color: rgba(color(gray-lighter), 0.5);
      margin-bottom: 8px;
      p {
        margin-left: 16px;
      }
    }
    &-child {
      background-color: color(gray-lighter);
      border-radius: 24px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__text {
    transition: opacity 0.3s;
    &-title {
      font-size: fontSize(headline-3);
      font-weight: 700;
      margin-bottom: 24px;
      max-width: 500px;
    }
    &-description {
      font-size: fontSize(headline-7);
      max-width: 500px;
      margin-bottom: 16px;
    }
  }
  &__promo {
    height: 28px;
    padding: 4px;
    border-radius: 28px;
    margin-right: auto;
    &-wrap {
      padding-left: 4px;
      padding-right: 8px;
      border-radius: 28px;
      background: linear-gradient(
        101.88deg,
        #ee3c6b 7.8%,
        #2d2bad 63.19%,
        #1bb1d0 90.08%
      );
      .d-icon:first-child {
        margin-right: 6px;
      }
      .d-icon:last-child {
        margin-left: 4px;
      }
    }
  }
  img {
    position: fixed;
    right: 108px;
    bottom: 57px;
    height: 250px;
    max-height: calc(100vh - 250px);
  }
  @media (max-width: getBreakpoint(desktop)) {
    padding: 80px 48px;
    img {
      right: 48px;
      bottom: 48px;
      max-height: 200px;
    }
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 12px;
    &__progress {
      &-parent {
        height: 20px;
        p {
          font-size: fontSize(paragraph);
        }
      }
      p {
        font-size: fontSize(paragraph);
      }
    }
    &__text {
      &-title {
        font-size: fontSize(headline-7);
        font-weight: 500;
        margin-bottom: 16px;
      }
      &-description {
        font-size: fontSize(paragraph);
      }
    }
    img {
      right: 30px;
      bottom: 24px;
      height: 128px;
      max-height: calc(100vh - 400px);
    }
  }
}
</style>
