import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import components_client_D6JrwJY69OuPhd5CpYVtG0cA7D2iGrTBgDSdJggVTuA from "/app/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_LXf2PKYaiBa8A1rT1EewHkpMgd7L_xLTLACU1dferBM from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import a_fetch_headers_gdLn5xIxtKBKBzp1pg4aFmHCIXH45mNWZotbhRyV6ro from "/app/plugins/a-fetch-headers.ts";
import apollo_mgb5iL8pimUkqqhu5n66JRv5YLad_Ana7KlAEOw6cu0 from "/app/plugins/apollo.ts";
import eventBus_HPNdAg_I8qVFEPDizPI_AFfsut3TFiqAaFEcG1Ahby0 from "/app/plugins/eventBus.ts";
import getCallCenterStatus_client_r_r2pE23YNd6rQCLfZAeSoR_LkUGmbj9x7JHdr7nHmo from "/app/plugins/getCallCenterStatus.client.ts";
import getCity_client_papAyoZClL3qicyWqE_hpb_f_m8OkrEJX6v0DRg4R0M from "/app/plugins/getCity.client.ts";
import polyfills_client_n4kVvEA2TSp_CCJ_NkYGcHWGqzj_6COcWPX1nfgUO6g from "/app/plugins/polyfills.client.ts";
import resizeWindow_client_Sn1zo7Aoudz_prudgl9zDziL6sd_w_u7txSxDJ9kIpc from "/app/plugins/resizeWindow.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import setCookie_client_UAz4M0L9rtYCw1cK00jiuUmuDvNFymAtXkbdGAdu2UA from "/app/plugins/setCookie.client.ts";
import thumbor_A076YAe8Ibs2Ff8Bn4ZVbag74_VIpeqW8BqVY_lPIDI from "/app/plugins/thumbor.ts";
import userInfo_client_7FR1L5GaLpPJi_HenD33eJ7X4MiI2M2gFsshsEEinjE from "/app/plugins/userInfo.client.ts";
import utm_client_K8Sp_uHek_Dq6jUzceoJFN9lIfIZSIppZJ1FhSxUdzo from "/app/plugins/utm.client.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/app/plugins/vuetify.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  components_client_D6JrwJY69OuPhd5CpYVtG0cA7D2iGrTBgDSdJggVTuA,
  plugin_LXf2PKYaiBa8A1rT1EewHkpMgd7L_xLTLACU1dferBM,
  a_fetch_headers_gdLn5xIxtKBKBzp1pg4aFmHCIXH45mNWZotbhRyV6ro,
  apollo_mgb5iL8pimUkqqhu5n66JRv5YLad_Ana7KlAEOw6cu0,
  eventBus_HPNdAg_I8qVFEPDizPI_AFfsut3TFiqAaFEcG1Ahby0,
  getCallCenterStatus_client_r_r2pE23YNd6rQCLfZAeSoR_LkUGmbj9x7JHdr7nHmo,
  getCity_client_papAyoZClL3qicyWqE_hpb_f_m8OkrEJX6v0DRg4R0M,
  polyfills_client_n4kVvEA2TSp_CCJ_NkYGcHWGqzj_6COcWPX1nfgUO6g,
  resizeWindow_client_Sn1zo7Aoudz_prudgl9zDziL6sd_w_u7txSxDJ9kIpc,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  setCookie_client_UAz4M0L9rtYCw1cK00jiuUmuDvNFymAtXkbdGAdu2UA,
  thumbor_A076YAe8Ibs2Ff8Bn4ZVbag74_VIpeqW8BqVY_lPIDI,
  userInfo_client_7FR1L5GaLpPJi_HenD33eJ7X4MiI2M2gFsshsEEinjE,
  utm_client_K8Sp_uHek_Dq6jUzceoJFN9lIfIZSIppZJ1FhSxUdzo,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]