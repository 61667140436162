<template>
  <article class="q-complete d-flex">
    <div class="container d-flex flex-column flex-grow">
      <header class="q-complete__header">
        <div class="q-complete__logo">
          <a
            :href="`/${currentCity?.url}/`"
            @click.prevent="$router.push({ path: `/${currentCity?.url}/` })"
          ><img
            loading="lazy"
            :src="'https://' + mainStore.domain + provider.provider.logo_partner"
            alt="logo"
          ></a>
        </div>
      </header>
      <div class="q-complete__wrap d-flex flex-column align-center flex-grow">
        <div class="q-complete__greetings">
          <h1 class="text-center">
            Спасибо за заявку
          </h1>
          <p class="text-center title">
            Ожидайте звонка специалиста
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

definePageMeta({
  layout: 'empty-p',
})

const cityStore = useCities()
const mainStore = useMainStore()
const currentCity = computed(() => cityStore.getCity)
const provider = computed(() => mainStore.getDomainConfig)
</script>

<style scoped lang="scss">
.q-complete {
  min-height: 100vh;
  padding-top: 24px;
  padding-bottom: 40px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 12px;
    padding-bottom: 24px;
  }

  &__header {
  }

  &__logo {
    img {
      max-width: 160px;
      margin-right: 32px;
      @media (max-width: getBreakpoint(tablet)) {
        max-width: 104px;
        margin-right: 12px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        max-width: 94px;
      }
    }
  }

  &__wrap {
  }

  &__greetings {
    margin-top: auto;
    margin-bottom: auto;

    h1 {
      font-size: 48px;
      line-height: 1.4;
      margin-bottom: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 28px;
      }
    }
    p {
      position: relative;

      &:after {
        content: "";
        pointer-events: none;
        background: url("@/assets/images/useful/arrow.png") 50% 50% no-repeat;
        transform: rotate(23deg);
        background-size: contain;
        position: absolute;
        right: 75px;
        bottom: -70px;
        width: 95px;
        height: 48px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 59px;
          height: 27px;
          right: 40px;
          bottom: -35px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 53px;
          height: 24px;
          right: 35px;
          bottom: -30px;
        }
      }
    }
  }
}
</style>
