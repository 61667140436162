import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'getCallCenterStatus',
  parallel: true,
  async setup() {
    const mainStore = useMainStore()
    if (mainStore.getDomainConfig) {
      mainStore.getCallCenterStatus()
    }
  },
})
