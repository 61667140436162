import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'userInfo',
  parallel: true,
  setup() {
    const { client } = useApolloClient()
    const mainStore = useMainStore()
    const userInfo = computed(() => mainStore.getUserAuth)
    const sessionid = useCookie('sessionid')
    const userInfoAsyncFetch = async () => {
      if (sessionid.value && !userInfo.value?.interactionOrdersCount) {
        const auth = (
          await client.query({
            query: gql`
              mutation Mutation {
                auth {
                  currentUserInfo {
                    lastName
                    firstName
                    siteUserId
                    verified
                    avatarUrl
                    interactionOrdersCount
                  }
                }
              }
            `,
          })
        ).data

        mainStore.$patch({
          userAuth: auth.auth.currentUserInfo,
        })
        if (!auth.auth?.currentUserInfo?.interactionOrdersCount)
          setTimeout(() => userInfoAsyncFetch, 60000)
      }
    }
    userInfoAsyncFetch()
  },
})
