<template>
  <div class="st-crush gray-p--bg">
    <div class="container">
      <h2 class="st-crush__title headline-3">Упала скорость интернета: основные причины</h2>
      <p class="st-crush__subtitle gray-b--text title-5">Вот самые распространённые причины, по которым падает скорость интернета:</p>

      <ol class="st-crush__list title-5">
        <li>Проблема у провайдера: вышло из строя оборудование, случилась перегрузка серверов и т.д.</li>
        <li>Проблемы с интернет-кабелем в квартире или снаружи.</li>
        <li>Непогода — гроза, сильный снегопад, порывы ветра.</li>
        <li>Вы случайно скачали вирус.</li>
        <li>Вы установили приложение, которое активно работает в фоновом режиме.</li>
        <li>К вашему wi-fi подключились соседи.</li>
        <li>Вы приобрели новый роутер и неправильно настроили его.</li>
        <li>На вашем компьютере закончилась оперативная память.</li>
        <li>Забит кэш браузера.</li>
        <li>Вы установили расширение в браузер, которое замедляет загрузку сайтов.</li>
        <li>Вы давно не обновляли ПО.</li>
      </ol>

      <div class="st-crush__card white--bg">
      <h3 class="st-crush__card-title title">Что делать?</h3>
      <ul>
        <li>Для начала исключите проблемы на стороне провайдера. Для этого позвоните в техподдержку вашего поставщика
          интернета.
        </li>
        <li>Убедитесь, что нет аварии и оборудование работает в нормальном режиме. Оператор также вам подскажет, в
          порядке ли кабель.
        </li>
        <li>Если поломок нет, исключите проблему с роутером — подключите провод напрямую к ПК. Если скорость не
          изменится, проверьте последние установленные приложения в настройках и удалите то, что установили недавно
          перед падением скорости.
        </li>
        <li>Если скорость падает именно с роутером, проверьте настройки. Возможно, вам нужно выбрать другие
          параметры.
        </li>
        <li>Почистите оперативную память и кэш браузера от мусора и старых файлов. Для этого есть много специальных
          программ.
        </li>
        <li>Смените антивирус на лицензионный и проведите глубокую проверку. Установите последние обновления
          программного обеспечения.
        </li>
        <li>Посмотрите в настройках, сколько устройств подключено к вашему wi-fi. Лишние удалите.</li>
        <li>Если у вас нет wi-fi, а скорость интернета резко упала, проверьте, не повреждён ли сетевой кабель и плотно
          ли он стоит в разъёме.
        </li>
        <li>Если есть поломка, не страшно. Замена провода — минутное дело.</li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.st-crush {
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 40px;
    padding-bottom: 40px;
  }


  &__title {
    margin-bottom: 4px;
  }
  &__subtitle {
    margin-bottom: 24px;
  }
  &__list {
    padding-left: 24px;
    margin-bottom: 24px;
    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__card {
    padding: 16px 24px 24px;
    border-radius: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px;
    }

    &-title {
      margin-bottom: 12px;
      font-weight: 700;
    }
    li {
      position: relative;
      padding-left: 16px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 7px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: color(secondary-dark);
      }
    }
  }
}
</style>