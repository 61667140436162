import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useCities } from '~/store/cites/cities.store'

export function useCompare() {
  const cityStore = useCities()
  const tariffsStore = useTariffs()
  const currentCity = computed(() => cityStore.getCity)

  const compareListTariffs = computed(
    () => tariffsStore.getCompareList[currentCity.value?.fias_id] || [],
  )

  const addCompare = (id: string, name: string) => {
    const isAdded = compareListTariffs.value.includes(id)
    tariffsStore.notifArr.splice(0, 5, { name, add: !isAdded, id })
    tariffsStore.setCompare(id)
  }

  return {
    compareListTariffs,
    addCompare,
  }
}
