<template>
  <section class="about-partners container">
    <h2 class="about-page__title">
      Наши партнеры
    </h2>
    <div class="about-partners__wrap">
      <img
        v-for="partner in partners"
        v-show="partner.logo"
        :key="partner.id"
        :alt="partner.name"
        :title="partner.name"
        :src="partner.logo ? ('https://' + mainStore.domain) + partner.logo : ''"
        loading="lazy"
      >
    </div>
    <div class="about-partners__join">
      <p class="headline-5">
        Присоединяйтесь
      </p>
      <a
        class="headline-4"
        href="mailto:privet@internet-tariff.ru"
      >privet@internet-tariff.ru</a>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const ctx = useNuxtApp()
const mainStore = useMainStore()

let errorStatus
let errorMessage
const { data: partners, error } = await useAsyncData(
  'Allpartners',
  async () => {
    try {
      return await $fetch(`${mainStore.apiDomain}/providers/partners/`, {
        method: 'GET',

        params: {},
      })
    }
    catch (e) {
      errorStatus = e.response.status
      errorMessage = e.message
      throw createError({
        statusCode: e.response.status,
        fatal: true,
        message: e.message,
      })
    }
  }, {
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }
      return data
    },
  },
)

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}
</script>

<style lang="scss" scoped>
.about-partners {
  padding-top: 200px;
  padding-bottom: 100px;

  .about-page__title {
    margin-bottom: 80px;
  }

  &__wrap {
    margin-bottom: 16px;
    margin-right: -48px;

    img {
      height: 40px;
      max-width: 140px;
      object-fit: contain;
      object-position: left;
      margin-right: 48px;
      margin-bottom: 64px;
    }
  }

  &__join {
    p {
      margin-bottom: 12px;
    }

    a {
      text-decoration: none;
      color: color(secondary-dark);
      transition: color 0.3s;

      &:hover {
        color: color(primary);
      }
    }
  }

  @media (max-width: getBreakpoint(desktop)) {
    padding-top: 108px;
    padding-bottom: 64px;
    .about-page__title {
      margin-bottom: 48px;
    }
    &__wrap {
      img {
        margin-right: 40px;
        margin-bottom: 56px;
      }
    }
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 80px;
    padding-bottom: 40px;
    .about-page__title {
      margin-bottom: 32px;
    }
    &__wrap {
      img {
        max-width: 90px;
        height: 24px;
        margin-right: 16px;
        margin-bottom: 24px;
      }
    }
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding-top: 64px;
    padding-bottom: 32px;
    .about-page__title {
      margin-bottom: 24px;
    }
  }
}
</style>
