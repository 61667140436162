<template>
  <section class="tariff-viewed">
    <h2 class="tariff-viewed__title">
      {{ title }}
    </h2>
    <LazyVSwiper
      slides-per-view="auto"
      :space-between="20"
      :threshold="3"
      class="relative"
    >
      <swiper-slide
        v-for="(tariff, index) in tariffsArr"
        :key="tariff.id + index"
      >
        <LazyPartnersTariffsSuggestonPartners
          :tariff="tariff"
          :provider="
            providers
              && providers.length
              && providers.find((el) => el.provider.id === tariff.provider.id)
              ? providers.find(
                (el) => el.provider.id === tariff.provider.id,
              )
              : null
          "
        />
      </swiper-slide>
    </LazyVSwiper>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {},
  tariffs: {},
  providers: {
    type: Array,
  },
})

const $route = useRoute()

const tariffsViewed = useCookie('tariffsViewed')
const tariffsArr = computed(() => {
  return tariffsViewed
    .value!.filter(el => el !== $route.params.id)
    .map(el => props.tariffs!.find(e => e.id === el.replace(/\D/g, '')))
    .filter(el => !!el)
})
</script>

<style scoped lang="scss">
.tariff-viewed {
  padding-top: 40px;
  padding-bottom: 48px;
  overflow: hidden;

  &:deep(.tariffs-sugg-new__btns) {
    display: none;
  }

  &:deep(.swiper-wrapper) {
    padding-top: 24px;
  }

  &__title {
    font-size: fontSize(headline-4);
    line-height: 1.4;
  }
}
</style>
