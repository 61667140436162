export default defineNuxtPlugin(() => {
  if (!('at' in Array.prototype)) {
    Array.prototype.at = function (index) {
      index = Math.trunc(index) || 0
      if (index < 0) index += this.length
      if (index < 0 || index >= this.length) return undefined
      return this[index]
    }
  }

  // Полифил для Object.hasOwn
  if (!Object.hasOwn) {
    Object.hasOwn = function (obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    }
  }
})
