import { ofetch } from 'ofetch'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin(() => {
  const mainStore = useMainStore()
  const {
    'x-real-ip': xRealIp,
    host,
    'x-utm-state': xUtm,
    cookie: ssrCookie,
  } = useRequestHeaders()
  const sessionidCookie = useCookie('sessionid')
  const createHeaders = () => {
    const headers: Record<string, string | null> = {
      'ip': xRealIp || null,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
    }

    if (sessionidCookie.value) {
      headers['X-Sessionid'] = sessionidCookie.value
    }

    if (import.meta.client) {
      const exclude = ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip']
      exclude.forEach(key => delete headers[key])
    }

    return headers
  }

  if (import.meta.client) {
    mainStore.$patch({
      apiDomain: `https://${mainStore.domain}/api`,
    })
  }
  globalThis.$fetch = ofetch.create({
    headers: createHeaders(),
  })
})
