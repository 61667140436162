<template>
  <LazyModalCityCheck
    v-if="city"
    @close="$emit('close')"
    @changed-city="changed"
  />

  <LazyModalCityChanged
    v-else-if="!city && !address"
    @close="$emit('close')"
    @change-city="city = true"
    @set-address="setAddress"
  />
  <LazyModalAddressCheckNew
    v-else-if="saWStreets && address"
    :re-open-city="true"
    @close="$emit('close')"
    @re-open="city = true"
  />
  <LazyModalAddressCheck
    v-else-if="address"
    :re-open-city="true"
    @close="$emit('close')"
    @re-open="city = true"
  />
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

const props = defineProps({
  showDialogAddress: {
    type: Boolean,
  },
  redirectToMainPage: {
    type: Boolean,
  },
})
const emit = defineEmits(['close'])

const $router = useRouter()
const mainStore = useMainStore()
const city = ref(true)
const address = ref(false)
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
const saWStreets = computed(() => mainStore.getSaWStreets)

const changed = () => {
  if (props.redirectToMainPage) {
    $router.push(`/${currentCity.value?.url}/`)
  }
  if (props.showDialogAddress) {
    emit('close')
    const inputAddress = document.getElementById('address-textarea')
    if (inputAddress) inputAddress.focus()
  }
  else city.value = false
}
const setAddress = () => {
  address.value = true
  nextTick(() => {
    const inputAddress = document.getElementById('address-textarea')
    if (inputAddress) inputAddress.focus()
  })
}
</script>

<style scoped lang="scss"></style>
