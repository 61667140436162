import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

export function getAddressLocation(returnOnly?: boolean) {
  const { client } = useApolloClient()
  const cityStore = useCities()
  const currentCity = computed(() => cityStore.getCity)

  const getAddressLoc = async (input: Record<string, any>) => {
    try {
      const res = await client.query({
        query: gql`
          query getLocation($input: LocationInfoInput!) {
            location {
              reducedLocation(input: $input) {
                fiasRegion
                hash
                autoGeneratedUrl
                addrObjFiasId
                houseFiasId
                streetFiasId
                fiasPath
                houseId
                addressName
                houseNumber
                houseType
                lat
                lon
                houseUrl
              }
            }
          }
        `,
        variables: {
          input: {
            addrObjFiasId: currentCity.value?.fias_id,
            ...input,
          },
        },
      })

      const locationData = res?.data?.location?.reducedLocation
      if (!locationData) return null

      if (!returnOnly && locationData.hash) {
        const houseCheck = Boolean(
          locationData.houseId
          || locationData.houseFiasId
          || (locationData.streetFiasId && locationData.houseNumber),
        )

        cityStore.house = locationData.autoGeneratedUrl && houseCheck ? locationData : {}
      }

      return locationData
    }
    catch (error) {
      console.error('Error fetching address location:', error)
      return null
    }
  }

  return { getAddressLoc }
}
