import type { RouterOptions } from '@nuxt/schema'

export default <RouterOptions>{
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()

    return new Promise((resolve) => {
      nuxtApp.hook('page:finish', () => {
        if (savedPosition) {
          resolve(savedPosition)
        }
        else if (
          (to.name?.toString().startsWith('city-tariffs')
            && from.name?.toString().startsWith('city-tariffs'))
          || ((to.name === 'city-providers-provider'
            || to.name === 'city-providers-provider-smartFilter')
          && (from.name === 'city-providers-provider'
            || from.name === 'city-providers-provider-smartFilter'))
        ) {
          resolve({})
        }
        else {
          resolve({
            top: 0,
          })
        }
      })
    })
  },
  routes: (_routes) => {
    const arr = _routes.map((el) => {
      return {
        ...el,
        path: el.path.replace('/:city(.*)*/providers/', '/:city(.*)+/providers/'),
      }
    })
    return arr
  },
}
