<template>
  <div class="reviews-comments__card">
    <div class="leave-comment__user d-flex align-center">
      <div class="reviews__avatar">
        {{ comment.author_name ? comment.author_name[0] : "?" }}
      </div>
      <span class="fwm">{{
        comment.author_name ? comment.author_name : "Гость"
      }}</span>
    </div>
    <p>{{ comment.text }}</p>
    <div>
      <div class="d-flex align-center review-card__comment-btnline flex-wrap">
        <button
          v-if="comment.answers.length"
          class="secondary-dark--text review-card__comment-btn"
          @click="showAdditional = !showAdditional"
        >
          {{
            comment.answers.length + " ответ" + counter(comment.answers.length)
          }}
        </button>
        <button
          class="secondary-dark--text review-card__comment-btn"
          @click="openLeaveComment"
        >
          {{ showLeaveComment ? "Скрыть" : "Ответить" }}
        </button>
        <div class="review-card__comment-likes d-flex align-center">
          <button
            :class="{ active: comment.current_user_reaction === true }"
            @click="setLike(true)"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/like.svg"
              alt="like"
              class="d-flex"
            >
          </button>
          <span class="subhead-3">{{ comment.likes }}</span>
          <button
            :class="{ active: comment.current_user_reaction === false }"
            @click="setLike(false)"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/dislike.svg"
              alt="like"
              class="d-flex"
            >
          </button>
        </div>
      </div>
      <LazyVCollapse :show-more="showAdditionalComments">
        <div class="reviews-comments__wrap">
          <div
            v-for="additionalComment in comment.answers"
            :key="additionalComment.id"
            class="reviews-comments__card"
          >
            <div class="leave-comment__user d-flex align-center">
              <div class="reviews__avatar">
                {{
                  additionalComment.author_name
                    ? additionalComment.author_name[0]
                    : "?"
                }}
              </div>
              <span class="fwm">{{
                additionalComment.author_name
                  ? additionalComment.author_name
                  : "Гость"
              }}</span>
            </div>
            <p>{{ additionalComment.text }}</p>
          </div>
        </div>
      </LazyVCollapse>
      <reviews-leave-comment
        :show-block="showLeaveComment"
        :review="review"
        :comment="comment"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { ProviderReview, ReviewComment } from '~/api'
import { counter } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'

export default defineComponent({
  name: 'ReviewsComment',
  props: {
    comment: {
      type: Object as PropType<ReviewComment>,
    },
    review: {
      type: Object as PropType<ProviderReview>,
    },
    watchLeaveComment: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const mainStore = useMainStore()
    const showAdditionalComments = computed(() => {
      return showAdditional.value
    })
    const openLeaveComment = () => {
      const value = showLeaveComment.value
      emit('closeLeaveComment')

      setTimeout(() => {
        showLeaveComment.value = !value
      }, 1)
    }

    const showAdditional = ref(false)

    const showLeaveComment = ref(false)

    watch(
      () => props.watchLeaveComment,
      () => {
        if (props.watchLeaveComment) showLeaveComment.value = false
      },
    )

    const setLike = async (val) => {
      try {
        await $fetch(`${mainStore.apiDomain}/reviews/${props.review.id}/react/`, {
          method: 'POST',

          body: {
            comment_id: props.comment?.id,
            like: val,
          },
        })
      }
      catch {}

      emit('updateComment')
    }

    return {
      showAdditionalComments,
      showAdditional,
      showLeaveComment,
      openLeaveComment,
      setLike,
      counter,
    }
  },
})
</script>

<style scoped></style>
