<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'

defineProps({
  provider: {},
  loc: {},
})
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
</script>

<template>
  <div class="rcs">
    <div
      class="rcs__header d-flex align-center justify-between"
    >
      <img
        loading="lazy"
        :src="/.*\.svg/.test(provider?.provider?.logoT?.unoptimized)
          ? provider?.provider?.logoT?.unoptimized
          : provider?.provider?.logoT?.url"
        :alt="provider.provider.name"
        class="rcs__logo"
      >
      <div
        v-if="provider?.score"
        class="rcs__rate d-flex align-center"
      >
        <div
          class="rcs__rate-score subhead-3 d-flex align-center justify-center white--text"
          :class="
            provider.score > 70
              ? 'additional--bg'
              : provider.score > 50
                ? 'additional-light--bg'
                : provider.score > 30
                  ? 'yellow-dark--bg'
                  : 'gray-2--bg'
          "
        >
          {{ provider?.score === 100 ? 10 : (provider?.score / 10).toFixed(1) }}
        </div>
      </div>
    </div>
    <span
      class="rcs__title subhead-3 d-block gray-dark--text"
    >{{ provider.provider.name }}</span>
    <div class="rcs__footer d-flex justify-between">
      <p class="title-3">
        {{ loc }} место
      </p>
      <a
        :href="`/${currentCity?.url}/providers/${provider.provider.slug}/`"
        class="rcs__link primary--text title-5"
        target="_blank"
      >Тарифы</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rcs {
  padding: 16px;
  background: #ffffff;
  border: 1px solid color(gray-darker);
  border-radius: 16px;
  transition: box-shadow 0.24s;
  scroll-snap-align: center;
  flex: 0 0 222px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: getBreakpoint(tablet)) {
    flex: 0 0 216px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    flex: 0 0 206px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    flex: 0 0 204px;
  }
  &:hover {
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);
  }

  &__header {
    margin-bottom: 8px;
  }
  &__logo {
    width: 124px;
    height: 31px;
    object-fit: contain;
    object-position: left;
    @media (max-width: getBreakpoint(tablet)) {
      width: 112px;
      height: 28px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 108px;
      height: 27px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 104px;
      height: 26px;
    }
  }
  &__rate {
    margin-left: auto;
    &-score {
      width: 25px;
      height: 22px;
      border-radius: 4px;
    }
  }
  &__title {
    margin-bottom: 8px;
  }
  &__link {
    &:before {
      content:  '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
