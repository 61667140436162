<template>
  <div
    ref="parent"
    data-test="modal-automatization-datepicker"
    class="relative ma-dt d-flex white--bg flex-wrap"
  >
    <div class="ma-dt__wrap d-flex flex-column">
      <LazyVSwiper
        :threshold="3"
        slides-per-view="auto"
        :space-between="4"
        :pagination="false"
      >
        <swiper-slide
          v-for="item in dateArr"
          :key="item.value"
          class="ma-dt__line subhead-3 text-no-wrap d-flex flex-column align-center"
          :class="{ active: item.value === date }"
          @click="setDate(item.value)"
        >
          {{ item.title }}
          <span
            class="gray-dark--text subhead-6"
            style="margin-top: -3px;"
          >{{ item.subtitle }}</span>
        </swiper-slide>
      </LazyVSwiper>
      <LazyVSwiper
        slides-per-view="auto"
        :space-between="4"
        :threshold="3"
        :pagination="false"
      >
        <swiper-slide
          v-for="item in timeArr"
          :key="item.value"
          class="ma-dt__line subhead-3 text-no-wrap"
          :class="{ active: item.value === time }"
          @click="setTime(item.value)"
        >
          {{ item.title }}
        </swiper-slide>
      </LazyVSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  date: {},
  time: {},
})

const emit = defineEmits(['update:date', 'update:time', 'close'])

const formatTime = (hours, minutes) => {
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

const formatDate = (date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  return `${day}.${month.toString().padStart(2, '0')}`
}
const formatDateWeek = (date) => {
  const daysOfWeek = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
  const dayOfWeek = daysOfWeek[date.getDay()]
  return dayOfWeek
}

const timeArr = computed(() => {
  const timeRanges = []
  let startHour = 8

  for (let i = 0; i < 7; i++) { // 7 intervals from 8:00-10:00 to 20:00-22:00
    const startTime = formatTime(startHour, 0)
    const endTime = formatTime(startHour + 2, 0)
    const title = `${startTime}-${endTime}`
    const value = startTime

    timeRanges.push({ title, value })
    startHour += 2
  }

  return timeRanges
})
const dateArr = computed(() => {
  const dateArray = []
  const today = new Date()

  for (let i = 1; i <= 30; i++) {
    const currentDate = new Date(today)
    currentDate.setDate(today.getDate() + i)

    const value = currentDate.toISOString().split('T')[0]

    const title = formatDate(currentDate)
    const subtitle = formatDateWeek(currentDate)

    dateArray.push({ value, title, subtitle })
  }

  return dateArray
})

const setDate = (val) => {
  emit('update:date', val)
}
const setTime = (val) => {
  emit('update:time', val)
}
</script>

<style scoped lang="scss">
.ma-dt {
  overflow: hidden;
  margin-bottom: 8px;

  &__wrap {
    position: relative;
    width: 100%;
  }

  --swiper-navigation-sides-offset: 0;
  &:deep(.swiper-wrapper) {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__line {
    padding: 8px 16px;
    border-radius: 12px;
    border: 1px solid color(gray-light-3);
    background: color(gray-p);
    &.active {
      background: color(white);
      border-color: var(--mainColor, color(primary));
    }
  }
}
</style>
