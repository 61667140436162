import { onError } from '@apollo/client/link/error'
import { createHttpLink, from } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { useMainStore } from '~/store/main/main.store'

let clientHeaders: Record<string, string> | null = null

export default defineNuxtPlugin(({ hook, callHook, $apollo }) => {
  const defaultClient = $apollo.defaultClient
  const mainStore = useMainStore()
  const getHeaders = () => {
    if (import.meta.client && clientHeaders) return clientHeaders

    const {
      'x-real-ip': xRealIp,
      host,
      'x-utm-state': xUtm,
      cookie: ssrCookie,
    } = useRequestHeaders() || {}

    const sessionidCookie = useCookie('sessionid')
    const sessionid = sessionidCookie?.value || null

    const headers: Record<string, string | null> = {
      'ip': xRealIp || null,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
      'X-Sessionid': sessionid,
    }
    // console.log(...headers)

    const cleanHeaders = Object.fromEntries(
      Object.entries(headers).filter(([_, v]) => v !== null),
    )

    if (import.meta.client) {
      ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip'].forEach(key => delete cleanHeaders[key])
      clientHeaders = cleanHeaders
    }

    return cleanHeaders
  }
  const defaultHeaders = getHeaders()

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...defaultHeaders,
      },
    }
  })

  const errorLink = onError((err) => {
    callHook('apollo:error', err)
  })

  const httpLink = createHttpLink({
    uri: import.meta.server ? process.env.VITE_NUXT_ENV_BACKEND_GRAPHQL_ROOT_URL : `https://${mainStore.getDomain}/api/graphql/`,
  })

  defaultClient.setLink(from([errorLink, authLink, httpLink]))

  defaultClient.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  }

  hook('apollo:error', (error) => {
    console.log('error: ', error)
  })
})
