<template>
  <section class=" container">
    <div class="p-bonus">
      <h2 class="p-bonus__title headline-2">
        Бонусы
      </h2>
      <div class="p-bonus__wrap d-flex flex-wrap">
        <div
          v-for="(item, i) in bonus"
          :key="'bonus' + i"
          class="p-bonus__item align-start d-flex"
        >
          <div class="p-bonus__item-img d-flex align-center justify-center">
            <img
              loading="lazy"
              :src="'https://' + mainStore.domain + item.icon"
              alt="bonus"
            >
          </div>
          <div class="p-bonus__item-text">
            <p class="p-bonus__item-title fwm">
              {{ item.title }}
            </p>
            <p class="p-bonus__item-descr gray-dark--text">
              {{ item.short_caption }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

defineProps({
  bonus: {},
})

const mainStore = useMainStore()
</script>

<style scoped lang="scss">
.p-bonus {
  padding-top: 40px;

  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }

  &__wrap {
    margin-right: -24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-right: 0;
    }
  }

  &__item {
    margin-right: 24px;
    flex: 0 0 calc(33.333% - 24px);
    margin-bottom: 24px;

    &-img {
      flex: 0 0 80px;
      height: 80px;
      //border-radius: 50%;
      //border: 1px solid color(gray-2);
      margin-right: 12px;
      img {
        max-width: 80px;
        max-height: 80px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        flex: 0 0 50px;
        height: 50px;
        img {
          max-width: 50px;
          max-height: 50px;
        }
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        flex: 0 0 44px;
        height: 44px;
        img {
          max-width: 44px;
          max-height: 44px;
        }
      }
    }
    &-title {
      font-size: fontSize(headline-7);
      line-height: 1.4;
      margin-bottom: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(headline-8);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 17px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
    }

    &-descr {
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 15px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
    }

    @media (max-width: getBreakpoint(desktop)) {
      flex: 0 0 calc(50% - 24px)
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-right: 0;
      flex: 0 0 100%;
      margin-bottom: 16px;
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 24px;
  }
}
</style>
