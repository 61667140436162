import { useTariffs } from '~/store/tariffs/tariffs.store'

export function recentSearchFn() {
  const tariffsStore = useTariffs()
  const recentSearch = toRef(tariffsStore, 'getRecentSearch')
  const isLoaded = ref(false)

  const isLocalStorageAvailable = (() => {
    try {
      const testKey = '__test__'
      localStorage.setItem(testKey, '1')
      localStorage.removeItem(testKey)
      return true
    }
    catch {
      return false
    }
  })()

  const loadRecentSearch = () => {
    if (!isLocalStorageAvailable) return

    const storedData = localStorage.getItem('recentSearch')
    if (storedData) {
      const parsedData = JSON.parse(storedData).filter(el => el.selectedHouse)
      tariffsStore.$patch({ recentSearch: parsedData })
    }
    isLoaded.value = true
  }

  const addRecentSearch = (address) => {
    if (!address?.houseUrl) return

    const filteredSearch = recentSearch.value.filter(el => el.houseUrl !== address.houseUrl)
    tariffsStore.$patch({ recentSearch: [address, ...filteredSearch] })
  }

  const clearRecentSearch = () => {
    tariffsStore.$patch({ recentSearch: [] })
  }

  if (isLocalStorageAvailable) {
    watchEffect(() => {
      if (isLoaded.value) {
        localStorage.setItem('recentSearch', JSON.stringify(recentSearch.value || []))
      }
    })
  }
  if (import.meta.client) {
    loadRecentSearch()
  }

  return {
    clearRecentSearch,
    addRecentSearch,
  }
}
