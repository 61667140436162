<template>
  <section class="st-check container">
    <h2 class="st-check__title headline-3">Как проверить скорость интернета</h2>
    <p class="st-check__subtitle title-5 gray-b--text">Перед тем как измерить скорость интернета, выполните эти рекомендации:</p>
    <div class="st-check__cards d-flex flex-wrap justify-between">
      <div class="st-check__card gray-p--bg title-5">Проверяйте скорость интернета через прямое проводное соединение. Если у вас Wi-Fi, отсоедините кабель от роутера и подключите его к ноутбуку или компьютеру</div>
      <div class="st-check__card gray-p--bg title-5">Перед тем как провести speedtest, закройте все вкладки, кроме этой, и отключите фоновые программы</div>
      <div class="st-check__card gray-p--bg title-5">Для наибольшей точности на время теста отключите антивирус</div>
      <div class="st-check__card gray-p--bg title-5">Выключите VPN и прокси</div>
    </div>
    <h3 class="st-check__sectitle title">Тестирование: инструкция</h3>
    <div class="st-check__steps d-flex justify-between">
      <div class="st-check__step blue--bg">
        <span class="title-5">1 шаг</span>
        <p class="title-4">Нажмите ВПЕРЁД</p>
        <span class="white--text">1</span>
      </div>
      <div class="st-check__step blue--bg">
        <span class="title-5">2 шаг</span>
        <p class="title-4">Подождите</p>
        <span class="white--text">2</span>
      </div>
      <div class="st-check__step blue--bg">
        <span class="title-5">3 шаг</span>
        <p class="title-4">Оцените результаты</p>
        <span class="white--text">3</span>
      </div>
    </div>
    <h3 class="st-check__sectitle title">Speedtest: расшифровка результатов</h3>
    <div class="st-check__cards d-flex flex-wrap justify-between">
      <div class="st-check__card gray-p--bg">
        <p class="title-4">PING (ПИНГ)</p>
        <span class="title-5">Это время, за которое пакет данных отправляется от пользователя на сервер и обратно. Чем ниже пинг, тем лучше. Самым хорошим считается показатель в 1 миллисекунду (мс).</span>
      </div>
      <div class="st-check__card gray-p--bg">
        <p class="title-4">Скачать (Download Speed)</p>
        <span class="title-5">В результатах Speedtest обозначает входящую пропускную способность — скорость загрузки данных на странице
и скачивания файлов. Чем выше этот показатель, тем быстрее
вы сможете сохранять на компьютер видео, фото, документы, смотреть кино онлайн без зависаний.</span></div>
      <div class="st-check__card gray-p--bg">
        <p class="title-4">Вибрация</p>
        <span class="title-5">Другими словами это колебания пинга.Тоже измеряется в миллисекундах. Высокая вибрация означает нестабильный пинг. Идеальный показатель по Speedtest равен нулю</span>
      </div>
      <div class="st-check__card gray-p--bg">
        <p class="title-4">Загрузить (Upload Speed)</p>
        <span class="title-5">Это исходящая скорость вашего интернета. Если показатель
по тесту низкий, вы не сможете вести онлайн-трансляции
и моментально отправлять тяжёлые файлы.</span></div>
    </div>
  </section>
</template>

<script>
</script>

<style scoped lang="scss">
.st-check {
  padding-top: 48px;
  padding-bottom: 24px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 40px;
    padding-bottom: 32px;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  &__sectitle {
    margin-top: 8px;
    margin-bottom: 12px;
    font-weight: 700;
  }

  &__card {
    width: calc(50% - 12px);
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid color(gray-darker);
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 4px;
    }
  }
  &__steps {

    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
  }

  &__step {
    width: calc(33.33% - 16px);
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid color(gray-darker);
    position: relative;
    span.white--text {
      position: absolute;
      right: -10px;
      top: -10px;
      direction: rtl;
      font-size: 120px;
      line-height: 120px;
      font-weight: 700;
      opacity: 0.5;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 100px;
        line-height: 100px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 8px;
    }
  }
}
</style>