<template>
  <div
    v-if="reviews"
    class="b-reviews container"
  >
    <h2 class="b-reviews__title headline-2">
      {{ title }}
    </h2>
    <LazyVSwiper
      slides-per-view="auto"
      :space-between="0"
      :threshold="3"
      class="relative b-reviews__wrap"
      itemscope
      itemtype="http://schema.org/ItemList"
    >
      <swiper-slide
        v-for="review in reviews.data"
        :key="review.id"
        class="b-reviews__card"
      >
        <LazyReviewsPageReviewsMyReviewsCard :review="review" />
      </swiper-slide>
    </LazyVSwiper>
    <a
      :href="`/${currentCity?.url}/reviews/`"
      class="b-reviews__btn primary--text title-5"
      @click.prevent="$router.push({ path: `/${currentCity?.url}/reviews/` })"
    >{{ btn }}</a>
  </div>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'

defineProps({
  title: {
    type: String,
    default: 'Отзывы о провайдерах',
  },
  btn: {
    type: String,
    default: 'Все отзывы',
  },
  reviews: {},
})

const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
</script>

<style scoped lang="scss">
.b-reviews {
  &__title {
    margin-bottom: 4px;
  }

  &:deep(.swiper-btn-wrap) {
    background: linear-gradient(
      90deg,
      rgba(color(gray-p), 0) 0%,
      color(gray-p) 100%
    );
  }
  &:deep(.swiper-pagination) {
    justify-content: flex-end;
  }

  &__wrap {
    margin-bottom: 4px;
  }
  &__card {
    min-width: 286px;
    @media (max-width: getBreakpoint(tablet)) {
      min-width: 260px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      min-width: 250px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      min-width: 240px;
    }
  }
}
</style>
