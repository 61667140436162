import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup() {
    if (!import.meta.dev) {
      const router = useRouter()
      Sentry.init({
        dsn: `https://f126cc0124ce4da9bce42728f2ce23e0@${import.meta.env.VITE_SENTRY_DOMAIN ? import.meta.env.VITE_SENTRY_DOMAIN : 'o452324.ingest.sentry.io'}/6462344`,
        environment: import.meta.env.VITE_BRANCH || 'development',

        integrations: [
          Sentry.browserTracingIntegration({ router }),
        ],
        tracesSampleRate: 0.03,
        tracePropagationTargets: ['localhost', /^https:\/\/dominternet\.ru\/api/],
        beforeSend(event, hint) {
          if (event.exception?.values?.[0]?.value === 'Cannot read properties of undefined (reading \'default\')') {
            if (shouldReload()) {
              console.warn('Перезагружаем страницу из-за ошибки \'default\'')
              reloadNuxtApp()
            }
            else {
              console.warn('Превышен лимит перезагрузок, сайт не будет перезагружен')
            }
          }
          if (event.exception) {
            console.error(
              `[Exeption handled by Sentry]: (${hint.originalException})`,
              { event, hint },
            )
            if (shouldIgnoreError(event)) {
              console.log('skip error')
              return null
            }
          }
          if (hint.originalException) {
            console.error('[Exception handled by Sentry]:', hint.originalException)

            // Преобразуем весь объект ошибки в строку
            if (hint.originalException) {
              try {
                event.extra = {
                  ...event.extra,
                  originalException: JSON.stringify(hint.originalException, (key, value) =>
                    typeof value === 'function' ? value.toString() : value,
                  ),
                }
              }
              catch (e) {
                event.extra = {
                  ...event.extra,
                  originalException: 'Failed to stringify exception',
                }
              }
            }

            // Преобразуем networkError и operation в строки
            if (hint.originalException?.networkError) {
              event.extra.networkError = JSON.stringify(hint.originalException.networkError, null, 2)
            }
            if (hint.originalException?.operation) {
              event.extra.operation = JSON.stringify(hint.originalException.operation, null, 2)
            }
          }
          return event
        },
      })
      return {
        provide: {
          sentryCaptureMessage: Sentry.captureMessage,
        },
      }
    }
    else {
      const captureMessage = (err) => {
        console.log(err)
      }

      return {
        provide: {
          sentryCaptureMessage: captureMessage,
        },
      }
    }
  },
})
function shouldReload() {
  const MAX_RELOADS = 3
  const TIME_WINDOW = 5 * 60 * 1000
  const now = Date.now()
  let reloads = []

  try {
    reloads = JSON.parse(localStorage.getItem('reload_attempts') || '[]')
  }
  catch (e) {
    try {
      reloads = JSON.parse(sessionStorage.getItem('reload_attempts') || '[]')
    }
    catch (e) {
      reloads = window._reloadAttempts || []
    }
  }

  const recentReloads = reloads.filter(timestamp => now - timestamp < TIME_WINDOW)

  if (recentReloads.length >= MAX_RELOADS) {
    return false
  }

  recentReloads.push(now)

  try {
    localStorage.setItem('reload_attempts', JSON.stringify(recentReloads))
  }
  catch (e) {
    try {
      sessionStorage.setItem('reload_attempts', JSON.stringify(recentReloads))
    }
    catch (e) {
      window._reloadAttempts = recentReloads
    }
  }

  return true
}

function shouldIgnoreError(event) {
  const ignoredMessages = [
    'Cannot read properties of undefined (reading \'focus\')',
    'InvalidStateError: Failed to get ServiceWorkerRegistration objects: The document is in an invalid state.',
    'Cannot read properties of null (reading \'offsetWidth\')',
    'null is not an object (evaluating \'e.domainName\')',
  ]

  return event.exception?.values?.some(ex => ignoredMessages.includes(ex.value))
}
