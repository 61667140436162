<template>
  <section
    v-if="devices?.length"
    class="provider-page__devices"
  >
    <div class="container">
      <div class="provider-page__devices-text">
        <p class="provider-page__devices-h2 headline-2">
          {{ title }}
        </p>
        <p class="provider-page__devices-subtitle">
          Доступные Wi-Fi роутеры и ТВ-приставки
        </p>
      </div>
    </div>
    <LazyTariffsPageTariffsServiceBtns
      :btns="btns"
      :tariff="deviceCategory"
      class="container"
      @select-tariffs="selectDevices"
    />
    <div class="provider-page__devices-swiper flex-grow container">
      <LazyVSwiper
        slides-per-view="auto"
        :space-between="12"
      >
        <swiper-slide
          v-for="device in devices.filter(
            (el) => el.device.kind + '' === deviceCategory[0],
          )"
          :key="device.id"
          class="swiper-slide"
        >
          <LazyProviderPageDeviceCard
            :device="device"
          />
        </swiper-slide>
      </LazyVSwiper>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
  devices: {},
  title: {
    default: 'Популярное оборудование',
  },
})
const btns = computed(() => {
  const arr = []
  if (props.devices?.filter(el => el.device.kind === 'ROUTER')?.length) {
    arr.push({
      title: 'Роутеры',
      value: ['ROUTER'],
      slug: 'ROUTER',
    })
  }
  if (props.devices?.filter(el => el.device.kind === 'TV_BOX')?.length) {
    arr.push({
      title: 'ТВ-приставки',
      value: ['TV_BOX'],
      slug: 'TV_BOX',
    })
  }
  return arr
})
const deviceCategory = ref(['ROUTER'])

const selectDevices = (val) => {
  deviceCategory.value = val
}

onMounted(() => {
  if (btns.value.length) selectDevices(btns.value[0].value)
})
</script>

<style scoped lang="scss">
.provider-page__devices {
  margin-bottom: 40px;

  &:deep(.swiper-btn-wrap) {
    background: linear-gradient(
      90deg,
      rgba(color(gray-light-3), 0) 0%,
      color(gray-light-3) 100%
    );
  }

  &-text {
    flex: 0 0 408px;
    position: relative;
    z-index: 1;
  }

  &-h2 {
    margin-bottom: 16px;
  }

  &-subtitle {
    margin-bottom: 44px;
    color: color(gray-dark);
  }

  &-btn {
    padding: 18px 32px;
    color: color(white);
    background: var(--gradient);
    border-radius: 28px;
  }

  @media (max-width: getBreakpoint(desktop)) {
    flex-direction: column;
    &-text {
      flex: unset;
      margin-bottom: 24px;
    }
    &-swiper {
      max-width: 100%;
    }
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding-bottom: 80px;
    position: relative;
    &-text {
      margin-bottom: 0;
    }
    &-btn {
      position: absolute;
      bottom: 0;
      left: 16px;
      right: 16px;
      text-align: center;
    }
  }
}
</style>
