<template>
  <div class="qt-device">
    <p class="title-4 qt-device__about">
      {{ selectedRouter.device.purpose && selectedRouter.device.purpose.description ? selectedRouter.device.purpose.description : selectedRouter.device.description }}
    </p>

    <p
      v-if="selectedRouter.device.techs && selectedRouter.device.techs.length"
      class="qt-device__properties d-flex"
    >
      <span class="d-flex gray-dark--text">Технология подключения<span class="flex-grow" /></span>{{ selectedRouter.device.techs.map(el => el.name).join(', ') }}
    </p>
    <p
      v-if="selectedRouter.device.otherTechs && tariff.techs[0].id !== '3'"
      class="qt-device__properties d-flex"
    >
      <span class="d-flex gray-dark--text">Другие технологии<span class="flex-grow" /></span>Через модем
    </p>
    <p class="qt-device__properties d-flex">
      <span class="d-flex gray-dark--text">Наличие Wi-Fi<span class="flex-grow" /></span>{{ selectedRouter.device.wifi && selectedRouter.device.wifi !== 'No' && selectedRouter.device.wifi !== 'NO' ? 'Да' : 'Нет' }}
    </p>
    <p
      v-for="item in properties"
      class="qt-device__properties d-flex"
    >
      <span class="d-flex gray-dark--text">{{ item.name }}<span class="flex-grow" /></span>{{ item.value }}
    </p>
    <button
      v-if="selectedRouter && selectedRouter.device.properties && selectedRouter.device.properties.length > (selectedRouter.device.otherTechs ? 1 : 0)"
      class="primary--text"
      style="margin-top: 12px;"
      @click="showMore = !showMore"
    >
      {{ showMore ? 'Скрыть' : 'Подробнее' }}
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  selectedRouter: {},
  tariff: {},
})

const showMore = ref(false)

const properties = computed(() => props.selectedRouter?.device.properties.filter((e, i) => showMore.value ? e : i < (props.selectedRouter.device.otherTechs ? 1 : 0)) || [])
</script>

<style scoped lang="scss">
.qt-device {
  &__about {
    margin-bottom: 4px;
  }
  &__properties {
    font-size: fontSize(paragraph);
    & > span {
      width: 50%;
      span {
        overflow-x: hidden;
        margin-right: 4px;
        margin-left: 4px;

        &:before {
          float: left;
          width: 0;
          white-space: nowrap;
          content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
        }
      }
    }

    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(label);
      & > span {
        width: 60%;
      }
    }
  }
}
</style>
