<template>
  <div
    class="notif-card white--bg"
    :class="{ hide: hide, small: small }"
    @click="closeCard"
  >
    <div
      v-if="card.add"
      class="notif-card__wrap"
    >
      <p class="subhead-3">
        Добавлен в список сравнения
      </p>
      <p class="subhead-3">
        {{ card.name }}
      </p>
      <span class="notif-card__subtitle d-block subhead-3">Всего тарифов в списке - {{ countTariffs }}</span>
      <button
        class="notif-card__btn d-btn d-btn--small d-btn--primary"
        @click="goToCompare"
      >
        Перейти в сравнение
      </button>
    </div>
    <div
      v-else
      class="notif-card__wrap"
    >
      <p class="subhead-3">
        Вы удалили из списка сравнения
      </p>
      <p class="subhead-3">
        {{ card.name }}
      </p>
      <span class="notif-card__subtitle d-block subhead-3">Всего тарифов в списке - {{ countTariffs }}</span>
      <button
        class="primary--text"
        @click="restore"
      >
        Восстановить
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  card: {},
})

const $route = useRoute()
const $router = useRouter()
const cityStore = useCities()
const tariffsStore = useTariffs()
const currentCity = computed(() => cityStore.getCity)
const notifArr = computed(() => tariffsStore.getNotifArr)
const compareListTariffs = computed(
  () => tariffsStore.getCompareList[currentCity.value?.fias_id] || [],
)
const countTariffs = ref()
const hide = ref(true)
const small = ref(false)
const closeCard = () => {
  hide.value = true
  setTimeout(() => {
    hide.value = true
    setTimeout(() => {
      const index = notifArr.value.map(el => el.id).indexOf(props.card?.id)
      tariffsStore.notifArr.splice(index, 1)
    }, 100)
  }, 100)
}
const goToCompare = () => {
  if ($route.hash === '#modalOpen') {
    $router.replace($route.fullPath.toString().replace('#modalOpen', ''))
    setTimeout(() => $router.push({ path: '/compare/', hash: '' }), 10)
  }
  else $router.push({ path: '/compare/', hash: '' })
}
const restore = () => {
  tariffsStore.setCompare(props.card?.id)
}
let timeout
onMounted(() => {
  nextTick(() => {
    hide.value = false
  })
  timeout = setTimeout(() => {
    closeCard()
  }, 3000)
})
onBeforeMount(() => {
  countTariffs.value = compareListTariffs.value?.length
})
onBeforeUnmount(() => {
  clearTimeout(timeout)
})

watch(() => notifArr.value,
  () => {
    if (notifArr.value?.map(el => el.id).filter(el => el === props.card?.id)?.length > 1) {
      closeCard()
    }
    if (notifArr.value?.length > 3) {
      if (notifArr.value?.[0]?.id === props.card.id) {
        closeCard()
      }
    }
  },
  {
    deep: true,
  })
</script>

<style scoped lang="scss">
.notif-card {
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0,0,0,.12);
  border: 1px solid #ebeef5;
  width: 330px;
  margin-bottom: 12px;
  transition-duration: .10s;
  transition-property: opacity, max-height;
  opacity: 1;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.small {
    max-height: 0;
  }
  &__wrap {
    padding: 12px 24px;
  }

  &__subtitle {
    margin-top: 8px;
    margin-bottom: 4px;
  }
  &__btn {
    padding: 0 12px;
  }
}
</style>
