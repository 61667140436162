<script setup lang="ts">

</script>

<template>
  <div class="blog-wrap relative">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.blog-wrap {
  margin-top: 16px;
  padding-left: calc(100% - 880px);

  @media (max-width: getBreakpoint(wide)) {
    padding-left: 270px;
  }

  @media (max-width: getBreakpoint(desktop)) {
    padding-left: 0;
  }
}
</style>
