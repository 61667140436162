import { useMainStore } from '~/store/main/main.store'
import type { CallsPhoneNumbers } from '~/api'

type GetCallsInput = {
  provider_id?: number | string
  provider_slug?: string
  domain?: string
  [key: string]: any
}

export default async function getCallsGeneral(input: GetCallsInput): Promise<CallsPhoneNumbers> {
  const mainStore = useMainStore()
  const res: CallsPhoneNumbers = await $fetch(`${mainStore.apiDomain}/calls/general/`, {
    method: 'GET',
    params: {
      ...input,
    },
  })
  return input?.provider_id === 6 || input?.provider_id === '6' || input?.provider_slug === 'mts'
    ? {
        ...res,
        support: null,
      }
    : res
}
