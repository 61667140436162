<template>
  <div
    class="dd-comments white--bg"
    data-test="comments-block"
  >
    <p class="dd-comments__title headline-6">
      Лента сообщений
    </p>
    <p class="dd-comments__subtitle title-2 gray-dark--text">
      Советы? Разочарования? Поделитесь ими с другими посетителями сайта:
    </p>
    <LazyDownDetectorDDCommentsForm
      ref="commentsForm"
      :monitoring="monitoring"
      :comments-count="comments?.length"
      :current-comment="currentComment"
      @update-reviews-list="$emit('updateReviewsList')"
      @update-user-auth="$emit('updateUserAuth')"
      @edit-comment="editComment"
    />

    <LazyDownDetectorDDComment
      v-for="comment in comments"
      :key="comment.id"
      :comment="comment"
      :current-comment="currentComment"
      @update-reviews-list="$emit('updateReviewsList')"
      @edit-comment="editComment"
    />
  </div>
</template>

<script setup lang="ts">
defineProps({
  comments: {},
  monitoring: {},
})
defineEmits(['updateReviewsList'])

const commentsForm = ref()
const currentComment = ref()

const editComment = (id?: number, text?: string, name?: string) => {
  currentComment.value = id
  commentsForm.value.textarea = text || ''
  commentsForm.value.clientName = name || ''
  commentsForm.value.validateName = true
}
</script>

<style scoped lang="scss">
.dd-comments {
  margin-top: 64px;
  width: calc(100% - 300px);
  border-radius: 12px;
  border: 1px solid color(gray-darker);
  padding: 24px;
  @media (max-width: getBreakpoint(desktop)) {
    width: unset;
  }
  @media (max-width: getBreakpoint(tablet)) {
    margin-top: 48px;
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0;
    border: none;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    margin-left: -8px;
    margin-right: -8px;
  }

  &__title {
    margin-bottom: 8px;
  }
  &__subtitle {
    margin-bottom: 24px;
  }
}
</style>
