<template>
  <section
    class="m_blog container"
    data-test="blog-block"
  >
    <h2 class="m-page__h2 m_blog__h2 headline-2">
      {{ title }}
    </h2>
    <p
      v-if="description"
      class="m_blog__subtitle title-5"
    >
      {{ description }}
    </p>
    <div
      v-if="articles && articles.results && articles.results.length"
      class="d-flex m_blog__wrap"
    >
      <article
        v-for="(post, index) in articles.results"
        :key="post.id"
        class="d-flex flex-column m_blog__article relative"
      >
        <div class="m_blog__article-img">
          <img
            loading="lazy"
            :src="post.preview_image"
            :alt="post.slug"
          >
        </div>
        <p class="m_blog__article-title black--text fwm">
          {{ post.title }}
        </p>
        <p class="m_blog__article-description gray-dark--text">
          {{ post.short_text }}
        </p>
        <a
          :href="`/blog/${post.slug}/`"
          class="m_blog__article-btn primary--text fwm"
          @click.prevent="goTo(`/blog/${post.slug}/`, index + 2)"
        >Подробнее</a>
      </article>
    </div>
  </section>
</template>

<script lang="ts">
export default defineComponent({
  name: 'MainPageBlogSection',
  props: {
    articles: {
      type: Object,
    },
    title: {
      type: String,
      default: 'Статьи и лайфхаки',
    },
    description: {
      type: String,
      default:
        'Полезные статьи о провайдерах и интернет-технологиях, обзоры и сравнения услуг операторов, их плюсы и минусы, а также экспертные рекомендации от нашей команды',
    },
  },
  setup() {
    const $router = useRouter()

    const goTo = (link) => {
      $router.push(link)
    }

    return {
      goTo,
    }
  },
})
</script>

<style scoped lang="scss">
.m_blog {
  margin-top: 64px;
  margin-bottom: 20px;
  @media (max-width: getBreakpoint(tablet)) {
    margin-top: 48px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    margin-top: 40px;
  }

  &__h2 {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 12px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-size: fontSize(headline-8);
    line-height: 1.4;
    margin-bottom: 64px;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(title);
      line-height: 1.5;
      margin-bottom: 60px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
    }
  }

  &__wrap {
    margin-left: -12px;
    margin-right: -12px;
    flex-wrap: wrap;
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 32px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__article {
    padding: 0 12px 24px 12px;
    flex: 0 0 33.33%;

    &:nth-child(4) {
      display: none;
    }

    @media (max-width: getBreakpoint(desktop)) {
      flex: 0 0 50%;
      margin-bottom: 24px;

      &:nth-child(4) {
        display: flex;
      }
    }

    @media (max-width: getBreakpoint(tablet)) {
      flex: 0 0 100%;
      flex-wrap: wrap;

      &:nth-child(4) {
        display: none;
      }
    }

    &-img {
      max-height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 16px;
      margin-bottom: 24px;

      img {
        transition: transform 0.24s;
      }
    }

    &-title {
      line-height: 24px;
      margin-bottom: 8px;
    }

    &-description {
      line-height: 24px;
      transition: opacity 0.24s;
      margin-bottom: 16px;
      opacity: 0;
      @media (max-width: getBreakpoint(tablet)) {
        display: none;
      }
    }

    &-btn {
      margin-top: auto;
      margin-right: auto;
      transition: opacity 0.24s;
      padding-right: 8px;
      opacity: 0;
      position: relative;

      &:after {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        right: -24px;
        top: 50%;
        transform: translateY(-50%);
        background: url("~/assets/images/svg/arrow-right_primary.svg") 50% 50%
          no-repeat;
      }

      @media (max-width: getBreakpoint(tablet)) {
        opacity: 0 !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }

    &:hover {
      .m_blog__article-img img {
        transform: scale(1.05);
      }

      .m_blog__article-description,
      .m_blog__article-btn {
        opacity: 1;
      }
    }
  }
}
</style>
