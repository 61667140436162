import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'utmClient',
  parallel: true,
  setup() {
    const $route = useRoute()
    const mainStore = useMainStore()
    const utmArrCookie = useCookie('utmArr')
    if ($route.fullPath.includes('utm')) {
      const utmArr = Object.entries($route.query).filter(([key]) => key.includes('utm'))

      if (utmArr.length) {
        mainStore.$patch({ utmArr })
        utmArrCookie.value = utmArr
      }
      else if (utmArrCookie.value) {
        mainStore.$patch({
          utmArr: utmArrCookie.value,
        })
      }
    }
  },
})
