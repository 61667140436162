import { useEventListener } from '@vueuse/core'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'resizeWindow',
  parallel: true,
  setup() {
    const ctx = useNuxtApp()
    const mainStore = useMainStore()
    const changeMenu = () => {
      mainStore.$patch({
        disabledPortal: window.innerWidth > 1000,
        disabledMobile: window.innerWidth > 767,
      })
    }
    changeMenu()
    if (ctx?.$device?.isIos || ctx?.$device?.isAndroid) {
      preloadComponents([
        'ModalAddressCheck',
        'ModalCityCheck',
        'ModalCallMe',
      ])
      const targetElement = document.querySelector('body')
      const event = new KeyboardEvent('keydown', {
        key: 'Enter',
      })
      targetElement.dispatchEvent(event)
    }
    useEventListener('resize', changeMenu)
  },
})
