<template>
  <div
    class="t-input d-flex"
    :class="{ 'err': errState && !disabled && showValid, 'valid': validState && showValid, 't-input__label': label, 't-input__label-active': modelInput || isFocused }"
    :data-placeholder="label ? placeholder : undefined"
  >
    <input
      ref="inputRef"
      v-model="modelInput"
      :autocapitalize="autocapitalize"
      spellcheck="false"
      :class="inputClass"
      :type="type"
      :enterkeyhint="enterkeyhint"
      :tabindex="tabindex"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :disabled="disabled"
      @keydown.enter="prevent"
      @keydown="checkDigits"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    >
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  value: {},
  validationLength: {},
  enterkeyhint: {
    type: String,
  },
  digitsOnly: {
    type: Boolean,
  },
  tabindex: {
    type: String,
  },
  label: {
    type: Boolean,
  },
  placeholder: {
    type: String,
    default: 'Имя',
  },
  autocomplete: {
    type: String,
    default: 'name',
  },
  autocapitalize: {
    type: String,
    default: 'off',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
  },
  showValid: {
    type: Boolean,
    default: true,
  },
  inputClass: {
    type: String,
    default: 'title-5',
  },
})
const emit = defineEmits(['update:value', 'validate', 'submit', 'onBlur'])
const modelInput = ref('')
const inputRef = ref()
const errState = ref(false)
const validState = ref(false)
const isFocused = ref(false)

const prevent = (e) => {
  e.preventDefault()
  emit('submit')
}
const checkDigits = (e) => {
  if (props.digitsOnly && !/^\d$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
    e.preventDefault()
  }
}
const onInput = () => {
  emit('update:value', modelInput.value)
  errState.value = false
  const valid = props.validationLength
    ? modelInput.value?.length >= props.validationLength
    : true
  if (props.validationLength) emit('validate', valid)
}

const onBlur = () => {
  isFocused.value = false
  nextTick(() => {
    const valid = props.validationLength
      ? modelInput.value?.length >= props.validationLength
      : true
    emit('validate', valid)
    errState.value = !valid
    validState.value = valid
    emit('onBlur')
  })
}
const onFocus = () => {
  isFocused.value = true
}

watch(
  () => props.value,
  () => {
    modelInput.value = props.value
    onInput()
  },
)

onMounted(() => {
  modelInput.value = props.value
  onInput()
})

defineExpose({ inputRef, onBlur })
</script>

<style scoped lang="scss">
.t-input {
  background: color(white);
  border-radius: 28px;

  &__label {
    position: relative;
    input::placeholder {
      font-size: 0;
      opacity: 0;
    }

    &:before {
      content: attr(data-placeholder);
      position: absolute;
      color: color(gray-inactive);
      left: 16px;
      top: 50%;
      font-size: inherit;
      line-height: inherit;
      transform: translateY(-50%);
      transition-property: font-size, top;
      transition-duration: .24s;
      pointer-events: none;
    }
    &-active {
      &:before {
        font-size: 12px;
        line-height: 12px;
        top: 10px;
      }
    }
    input {
      padding-top: 12px;
    }
  }
  input {
    height: 54px;
    border-radius: 28px;
    padding-left: 24px;
    border: 1px solid color(gray-2);
    width: 100%;
    transition: border-color 0.24s;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }

    &::placeholder {
      color: color(gray-inactive);
    }

    &:focus {
      border-color: color(secondary-dark);
    }

    &:disabled {
      opacity: .7;
    }
  }

  &.valid {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 7px;
      right: 20px;
      top: calc(50% - 5px);
      border-left: 1.5px solid color(secondary-dark);
      border-bottom: 1.5px solid color(secondary-dark);
      transform: rotate(-45deg);
    }
  }

  &.err {
    position: relative;
    input {
      border-color: color(red);
    }
    &:after {
      content: 'Слишком короткое имя';
      position: absolute;
      color: color(red);
      font-size: 11px;
      top: calc(100%);
    }
  }
}
</style>
