import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin({
  name: 'setCookie',
  parallel: true,
  setup() {
    const cityStore = useCities()
    const tariffsStore = useTariffs()
    const mainStore = useMainStore()
    const currentCity = computed(() => cityStore.getCity)
    const houseUrl = computed(() => cityStore.getHouseUrl)
    const partner = mainStore.getDomainConfig

    const cityFromCookie = useCookie('currentCity')
    const addressFromCookie = useCookie('currentAddress')
    const compareList = useCookie('compare')?.value

    cityFromCookie.value = currentCity.value?.fias_id
    addressFromCookie.value = houseUrl.value || null

    if (compareList && !partner)
      tariffsStore.$patch({ compareList })

    watch(
      () => currentCity.value?.fias_id,
      (newFiasId) => {
        if (newFiasId) {
          cityFromCookie.value = newFiasId
        }
      },
    )
    watch(
      () => houseUrl.value,
      (newHouseUrl) => {
        addressFromCookie.value = newHouseUrl || null
      },
    )
  },
})
