<template>
  <div
    class="provider-page__devices-card d-flex flex-column"
    data-test="device-card"
  >
    <div class="provider-page__devices-img">
      <img
        loading="lazy"
        :src="device.device.image ? device.device.image : ''"
        :alt="device.device.kindName"
      >
    </div>
    <span class="title-5 gray-dark--text">{{ device.device.kindName }}</span>
    <p class="provider-page__devices-model headline-7">
      {{ device.device.description }}
    </p>
    <template v-if="device.paymentMethods?.[0]">
      <span class="provider-page__devices-payment">{{
        payMethod(device.paymentMethods?.[0].method)
      }}</span>
      <p class="provider-page__devices-price headline-2">
        <template
          v-if="
            device.paymentMethods?.[0].method === 'RENT'
              || device.paymentMethods?.[0].method === 'INSTALLMENT'
          "
        >
          {{ device.paymentMethods?.[0].monthlyPayment }}
          <span class="title-5 gray-dark--text">{{ "₽/мес" }}</span><span v-show="device.paymentMethods?.[0].singlePayment">{{
            " + "
          }}</span>{{
            device.paymentMethods?.[0].singlePayment
              ? device.paymentMethods?.[0].singlePayment
              : ""
          }}
          <span
            v-show="device.paymentMethods?.[0].singlePayment"
            class="title-5 gray-dark--text"
          >{{
            "₽ сразу"
          }}</span>
        </template>
        <template v-else>
          {{ device.paymentMethods?.[0].cost || "0" }} <span>₽</span>
        </template>
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
defineProps({
  device: {
    type: Object,
    required: true,
  },
})

const payMethod = (val) => {
  switch (val) {
    case 'INCLUDE':
      return 'Включен'
    case 'RENT':
      return 'Аренда'
    case 'INSTALLMENT':
      return 'Рассрочка'
    case 'BUY':
      return 'Покупка'
  }
}
</script>

<style scoped lang="scss">
.provider-page__devices {
  &-card {
    box-shadow: 4px 4px 15px rgba(3, 5, 32, 0.1);
    background-color: color(white);
    border-radius: 16px;
    padding: 16px;
    width: 341px !important;
    max-width: calc(100vw - 48px);
  }

  &-img {
    background: color(gray-light-3);
    border: 1px solid color(gray-light);
    border-radius: 15px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    img {
      max-height: 132px;
      max-width: 160px;
    }
  }

  &-model {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  &-payment {
    margin-top: auto;
  }

  &-price {
    margin-top: 4px;
  }
}
</style>
