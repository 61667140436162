<template>
  <div class="reviews-comments">
    <LazyVCollapse :show-more="showComments">
      <div class="reviews-comments__wrap">
        <LazyReviewsPageReviewsComment
          v-for="comment in comments"
          :key="comment.id"
          class="reviews-comments__card"
          :comment="comment"
          :review="review"
          :watch-leave-comment="watchLeaveComment"
          @close-leave-comment="closeLeaveComment"
          @update-comment="$emit('updateComment')"
        />
      </div>
    </LazyVCollapse>
  </div>
</template>

<script lang="ts" setup>
import type { ProviderReview, ReviewComment } from '~/api'

const props = defineProps({
  showBlock: {
    type: Boolean,
  },
  comments: {
    type: Array as PropType<ReviewComment[]>,
  },
  review: {
    type: Object as PropType<ProviderReview>,
  },
})
const emit = defineEmits(['closeLeaveComment', 'updateComment'])
const showComments = computed(() => {
  return props.showBlock
})

const watchLeaveComment = ref(false)

const closeLeaveComment = () => {
  watchLeaveComment.value = true
  emit('closeLeaveComment')

  setTimeout(() => {
    watchLeaveComment.value = false
  }, 10)
}

defineExpose({ closeLeaveComment })
</script>

<style scoped lang="scss">
.reviews-comments {
  &__card {
    margin-bottom: 12px;
  }
  &__wrap {
    &:deep(.reviews-comments__wrap) {
      padding-left: 40px;
    }
    &:deep(.leave-comment) {
      padding-left: 40px;
    }
  }
}
</style>
