<template>
  <section class="p-cover container">
    <h2 class="p-cover__title headline-3">
      {{ title }}
    </h2>
    <p class="title-5 gray-dark--text">
      {{ subtitle }}
    </p>
    <LazyVSwiper
      slides-per-view="auto"
      :space-between="disabledPortal ? 16 : 8"
      :threshold="3"
      class="relative"
    >
      <template
        v-for="(provider, index) in providers"
        :key="provider.provider.id"
      >
        <swiper-slide>
          <LazyProvidersPageProvidersCardNew
            :provider="provider"
            :provider-index="index"
          />
        </swiper-slide>
      </template>
    </LazyVSwiper>
  </section>
</template>

<script lang="ts" setup>
import { useMainStore } from '~/store/main/main.store'

defineProps({
  providers: {
    type: Array,
  },
  textAgg: {},
  title: {
    type: String,
    default: 'Провайдеры интернета',
  },
  subtitle: {
    type: String,
    default:
      'Проверьте, какие из провайдеров доступны в вашем доме. Нажмите «Проверить адрес» в карточке любого провайдера.',
  },
})

const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)
</script>

<style scoped lang="scss">
.p-cover {
  padding-top: 48px;
  padding-bottom: 24px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 40px;
    padding-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 12px;
    }
  }
  &:deep(.swiper-wrapper) {
    padding-top: 16px;
  }
  &:deep(.swiper-pagination) {
    justify-content: flex-end;
  }
  &__subtitle {
    margin-bottom: 8px;
    margin-top: 4px;
    transition: color 0.24s;
    &:hover {
      color: color(primary);
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 2px;
    }
  }
}
</style>
